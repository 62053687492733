<template>
  <available-components-for-theme3 :specific-components="components" />
</template>
<script lang="ts">
import AvailableComponentsForTheme3 from '~/components/theme3/AvailableComponentsForTheme3.vue';
import { ViewsMixin } from '~/mixins/views.mixin';

export default defineNuxtComponent({
  name: 'Theme3View',

  mixins: [ViewsMixin],

  components: {
    AvailableComponentsForTheme3,
  },

  description:
    'Components that can be used through this theme should be imported here in order to use them dynamically',
});
</script>
